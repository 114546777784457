<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/locations">Standorte</router-link></li>
              <li>{{ location.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link :to="'/admin/locations/'+this.$route.params.id+'/users/new'" class="button button-orange button-small">Mitarbeiter hinzufügen ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/locations/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/locations/'+this.$route.params.id+'/hours'">Öffnungszeiten</router-link></li>
            <li class="active"><router-link :to="'/admin/locations/'+this.$route.params.id+'/users'">Mitarbeiter</router-link></li>
          </ul>

          <div v-if="search_loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>

            <div v-if="users.length == 0">
              <p>Dieser Standort hat noch keine Mitarbeiter.</p>
            </div>
            <div v-else>

              <table class="table">
                <thead>
                  <tr>
                    <th width="30%">Name</th>
                    <th width="30%">E-Mail</th>
                    <th width="30%">Rolle</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" v-bind:key="user.id">
                    <td>{{ user.user.first_name }} {{ user.user.last_name }}</td>
                    <td>{{ user.user.email }}</td>
                    <td>
                      <span v-if="user.role == 'staff'">Mitarbeiter</span>
                    </td>
                    <td>
                      <a @click="delete_user(user)" href="#">
                        <span class="material-icons">delete</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      loading: true,
      search_loading: false,
      errors: [],
      location: {},
      users: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.location = response.data.location;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/users', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.users = response.data.users;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })
    },
    delete_user(user) {
      axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/locations/'+this.$route.params.id+'/users/'+user.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Mitarbeiter erfolgeich gelöscht.',
        });
        this.get_data();
      })
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
